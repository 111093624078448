export const GQL_KNOWLEDGE_BASE_PAGE_HERO_COMPONENT_ENTRY = `
  organizationHeroBanner: pageComponentsCollection(where: { sys: { id: "provide-id-here" } }, limit: 1) {
    items {
        ... on Banner {
        bodyCopy {
          json
        }
        heroLogo {
          url
          width
          height
          description
        }
        heroImage {
          url
          description
          width
          height
        }
      }
    }
  }
`;

// ADD MORE COMPONENT ENTRIES BELOW
