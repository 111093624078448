import React from 'react';
import { ParagraphBreakPropTypes } from './ParagraphSeparator.types';
const ParagraphBreak: React.FC<ParagraphBreakPropTypes> = ({
  className = '',
  style = {},
  children
}) => {
  return <div style={style} className={`[&>*:not(:last-child)]:mb-[1.6rem] ${className}`} data-sentry-component="ParagraphBreak" data-sentry-source-file="ParagraphSeparator.tsx">
      {children}
    </div>;
};
export default ParagraphBreak;